import './App.css';

function App() {
  return (
  <div className="App">
    <header className="App-header">
      <p>
      Isa Rasheed
      </p>
    </header>
  </div>
  );
}

export default App;
